import { Painter } from "./painter";

export class SpectoWaveVisualizerPainter extends Painter {
  // https://webaudioapi.com/samples/visualizer/
  public paint = () => {
    if (!this.analyser || !this.canvasRef.current) return;
    const canvas = this.canvasRef.current;
    const context = canvas.getContext("2d", { willReadFrequently: true });
    if (context === null) return;

    const playback = () => {
      const freqs = new Uint8Array(this.analyser.frequencyBinCount);
      const times = new Uint8Array(this.analyser.frequencyBinCount);
      this.analyser.smoothingTimeConstant = 0.8;
      this.analyser.getByteFrequencyData(freqs);
      this.analyser.getByteTimeDomainData(times);
      context.fillStyle = "black";
      context.fillRect(0, 0, canvas.width, canvas.height);
      for (let i = 0; i < this.analyser.frequencyBinCount; i++) {
        const value = freqs[i] * 12;
        const percent = value / 256;
        const height = canvas.height * percent;
        const offset = canvas.height - height - 1;
        const barWidth = canvas.width * 4 / this.analyser.frequencyBinCount;
        const hue = i / this.analyser.frequencyBinCount * 360;
        context.fillStyle = "hsl(" + hue + ", 100%, 50%)";
        context.fillRect(i * barWidth, offset, barWidth, height);
      }
      for (let i = 0; i < this.analyser.frequencyBinCount; i++) {
        const value = times[i];
        const percent = value / 256;
        const height = canvas.height * percent;
        const offset = canvas.height - height - 1;
        const barWidth = canvas.width / this.analyser.frequencyBinCount;
        context.fillStyle = "white";
        context.fillRect(i * barWidth, offset, 1, 2);
      }
      requestAnimationFrame(playback);
    };
    playback();
  };
}
