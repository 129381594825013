import "./App.css";
import Guitar from "./guitar/guitar";

function App () {
  return (
    <div className="App">
      <Guitar />
    </div>
  );
}

export default App;
