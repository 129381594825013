import { Painter } from "./painter";

export class WaveformVisualizerPainter extends Painter {
  public paint = () => {
    if (!this.analyser) return;
    const canvas = this.canvasRef.current;
    if (!canvas) return;
    const context = canvas.getContext("2d", { willReadFrequently: true });
    if (context == null) return;
    const bufferLength = this.analyser.fftSize;
    const dataArray = new Uint8Array(bufferLength);

    const animate = () => {
      requestAnimationFrame(animate);
      this.analyser.getByteTimeDomainData(dataArray);
      context.fillStyle = "rgb(200, 200, 200)";
      context.fillRect(0, 0, canvas.width, canvas.height);
      context.lineWidth = 1;
      context.strokeStyle = "rgb(0, 0, 0)";
      context.beginPath();
      const sliceWidth = canvas.width * 1.0 / bufferLength;
      let x = 0;
      for (let i = 0; i < bufferLength; i++) {
        const v = (dataArray[i] / 128.0) * 8;
        const y = v * canvas.height / 2 - (canvas.height * 3.5);
        if (i === 0) {
          context.moveTo(x, y);
        } else {
          context.lineTo(x, y);
        }
        x += sliceWidth;
      }
      context.lineTo(canvas.width, canvas.height / 2);
      context.stroke();
    };
    animate();
  };
}
