import { useRef } from "react";
import { VisualizerProps, useVisualizer } from "./useVisualizer";

export const Visualizer = (props: VisualizerProps) => {
  const { analyser, active, sampleRate, type } = props;
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useVisualizer(analyser, type, canvasRef, active, sampleRate);

  return <canvas ref={canvasRef} />;
};
