import { Painter } from "./painter";

export class OsciloscopeVisualizerPainter extends Painter {
  public paint = () => {
    if (!this.analyser) return;
    const canvas = this.canvasRef.current;
    if (!canvas) return;
    const context = canvas.getContext("2d", { willReadFrequently: true });
    if (context == null) return;
    const bufferLength = this.analyser.fftSize;
    const dataArray = new Float32Array(bufferLength);

    const animate = () => {
      this.analyser.getFloatTimeDomainData(dataArray);
      context.clearRect(0, 0, canvas.width, canvas.height);
      context.beginPath();
      const sliceWidth = canvas.width / bufferLength;
      let x = 0;
      for (let i = 0; i < bufferLength; i++) {
        const v = dataArray[i] * 200.0;
        const y = canvas.height / 2 + v;
        if (i === 0) {
          context.moveTo(x, y);
        } else {
          context.lineTo(x, y);
        }
        x += sliceWidth;
      }
      context.lineTo(canvas.width, canvas.height / 2);
      context.stroke();
      requestAnimationFrame(animate);
    };
    animate();
  };
}
