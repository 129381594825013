interface EffectProps {
  name: string;
  isEnabled: boolean;
  onChange: (value: boolean) => void;
  val?: number;
  onValChange?: (value: number) => void;
}

export const Effect = (props: EffectProps) => {
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange(event.target.checked);
  };
  return (
      <div style={{ width: "200px", border: "1px solid black", padding: "10px", margin: "10px" }}>
        <h2>{props.name}</h2>
        <div>
          <label>
            <input type="checkbox" checked={props.isEnabled} onChange={handleCheckboxChange} />
          </label>
        </div>
      </div>
  );
};

export const MainEffect = (props: EffectProps) => {
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange(event.target.checked);
  };

  const handleVolumeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const volume = Number(event.target.value);
    props.onValChange && props.onValChange(volume);
  };

  return (
      <div style={{ width: "200px", border: "1px solid black", padding: "10px", margin: "10px" }}>
        <h2>{props.name}</h2>
        <div>
          <label>
            <input type="checkbox" checked={props.isEnabled} onChange={handleCheckboxChange} />
          </label>
        </div>
        <div>
          <label>
            Volume:
            <input type="range" min="0" max="100" step="1" value={props.val} onChange={handleVolumeChange} />
          </label>
        </div>
      </div>
  );
};
