/* eslint-disable @typescript-eslint/no-floating-promises */
import { useEffect, useMemo, useState } from "react";
import { useUserMedia } from "./useUserMedia";
import { createDistortionNode } from "./effects/disortion";
import { createEchoNode } from "./effects/echo";
import { createGainNode } from "./effects/gain";
import { Effect, MainEffect } from "./effects/effect";
import { debounce } from "../common/debounce";
import { Visualizer } from "./visualizers/visualizer";
import { PainterType } from "./visualizers/painters";
import { AudioRecorder } from "./tools/recorder/recorder";

const CAPTURE_OPTIONS = {
  audio: {
    echoCancellation: false,
    autoGainControl: false,
    noiseSuppression: false
  },
  video: false
};

const createPlugins = (audioContext: AudioContext | null): Record<string, AudioNode | null> => {
  const plugins: Record<string, AudioNode | null> = {
    distortion: createDistortionNode(audioContext, 1000),
    echo: createEchoNode(audioContext, 0.5, 0.5),
    gain: createGainNode(audioContext, 5)
  };
  return plugins;
};

export function createMainNode (audioContext: AudioContext, volume: number): GainNode {
  const main = audioContext.createGain();
  main.gain.value = (volume / 100) * 10;
  return main;
}

function Guitar () {
  const [mainState, setMain] = useState<{ on: boolean; val: number }>({ on: false, val: 50 });
  const { audioContext, mediaStreamSource, mediaStreamDestination } = useUserMedia(mainState.on ? CAPTURE_OPTIONS : undefined);
  const analyser = useMemo(() => audioContext ? audioContext.createAnalyser() : null, [audioContext]);
  const [error] = useState<Error | null>(null);
  const mainPlugin = useMemo(() => audioContext ? audioContext.createGain() : null, [audioContext]);
  const plugins = useMemo(() => createPlugins(audioContext || null), [audioContext]);
  const initialPluginState = useMemo(() => {
    const state: Record<string, boolean> = {};
    plugins && Object.keys(plugins).forEach(plugin => {
      state[plugin] = false;
    });
    return state;
  }, []);
  const initiaVisualizerState = useMemo(() => {
    const state: Record<string, boolean> = {
      spectoWaveExtended: true,
      tuner: true,
      frequency: true,
      bars: true,
      spectoWave: true,
      waveform: true,
      oscilloscope: true,
      a: true
    };
    return state;
  }, []);
  const [pluginState, setPluginState] = useState<Record<string, boolean>>(initialPluginState);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [visualizerState, setVisualizerState] = useState<Record<string, boolean>>(initiaVisualizerState);
  const handlePluginChange = (plugin: string, value: boolean) => {
    setPluginState(prevState => ({ ...prevState, [plugin]: value }));
  };
  const handleMainPluginChange = debounce((on: boolean, val: number) => {
    setMain({ on, val });
  }, 115);

  const disconnectPlugins = (plugins: Record<string, AudioNode | null>) => {
    for (const key in plugins) {
      if (!plugins[key]) continue;
      !pluginState[key] && plugins[key]?.disconnect();
    }
  };
  const disconnectMainPlugin = () => {
    mainPlugin?.disconnect();
  };
  const disconnectSource = () => {
    mediaStreamSource?.disconnect();
    mediaStreamSource?.context.destination?.disconnect();
  };
  const disconnectDestination = () => {
    audioContext?.destination.disconnect();
  };
  const disconnectAll = () => {
    disconnectSource();
    disconnectPlugins(plugins);
    disconnectMainPlugin();
    disconnectDestination();
  };

  const connectAll = () => {
    audioContext?.resume();
    if (!mediaStreamSource || !audioContext) return;
    let previousNode: AudioNode = mediaStreamSource;
    for (const key in plugins) {
      if (!pluginState[key] || !plugins[key]) continue;
      const plugin = plugins[key];
      if (!plugin) continue;
      previousNode.connect(plugin);
      previousNode = plugin;
    }
    if (mainPlugin && audioContext) {
      mainPlugin?.connect(audioContext.destination);
      if (analyser) {
        mediaStreamSource.connect(analyser);
        analyser.connect(audioContext.destination);
      }
      mediaStreamDestination && mainPlugin.connect(mediaStreamDestination);
      previousNode.connect(mainPlugin);
    }
  };

  useEffect(() => {
    disconnectAll();
    if (!mediaStreamSource) return;
    if (mainPlugin && mainState.on) {
      mainPlugin.gain.value = mainState.on ? (mainState.val / 100) * 10 : 0;
      connectAll();
    }
  }, [plugins, pluginState, mainState, mediaStreamSource]);

  if (error) {
    return (<div>Error: {error.message}</div>);
  }
  return (
    <>
    <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
      <MainEffect
        name="main"
        isEnabled={mainState.on}
        onChange={value => { handleMainPluginChange(value, mainState.val); }}
        val={mainState.val}
        onValChange={(v) => { handleMainPluginChange(mainState.on, v); }}
      ></MainEffect>
      {plugins && Object.keys(plugins).map(plugin => (
        <Effect
          key={plugin}
          name={plugin}
          isEnabled={pluginState != null && pluginState[plugin]}
          onChange={value => { handlePluginChange(plugin, value); }}
        />
      ))}
    </div>
    <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
    {analyser && audioContext && mediaStreamSource && Object.keys(plugins).length > 0 && Object.keys(visualizerState).map(plugin => {
      console.log("plugin", plugin);
      return (
          <div key={plugin} style={{ margin: "20px", border: "1px solid black" }}>
            <Visualizer type={plugin as PainterType} analyser={analyser} key={ JSON.stringify(mainState) + JSON.stringify(pluginState) } active={mainState.on} sampleRate={audioContext.sampleRate} ></Visualizer>
          </div>
      );
    })}
    { mediaStreamDestination && audioContext && (<AudioRecorder mediaStream={mediaStreamDestination.stream} sampleRate={audioContext.sampleRate}></AudioRecorder>) }
    </div>
    </>
  );
}

export default Guitar;
