// eslint-disable-next-line @typescript-eslint/ban-types
export function debounce<T extends Function> (func: T, wait: number, immediate = false): T {
  let timeout: number | null;
  return function (this: any) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const context = this;
    const args = arguments;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    timeout && clearTimeout(timeout);
    timeout = window.setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  } as any;
}
