import { RefObject, useEffect, useRef } from "react";
import { painterFactory, PainterType } from "./painters";
import { Painter } from "./painter";

export interface VisualizerProps {
  type: PainterType;
  analyser: AnalyserNode;
  active: boolean;
  sampleRate: number;
}

export const useVisualizer = (analyser: AnalyserNode, type: PainterType, canvasRef: RefObject<HTMLCanvasElement>, active: boolean, sapleRate: number) => {
  const paintersRef = useRef<Map<PainterType, Painter>>(new Map());
  useEffect(() => {
    if (!analyser || !active) return;
    analyser.fftSize = 2048;

    if (!paintersRef.current.has(type)) {
      const painter = painterFactory(type, analyser, canvasRef, active, sapleRate);
      paintersRef.current.set(type, painter);
    }
    const painter = paintersRef.current.get(type);
    painter?.paint();
  }, [analyser, type, canvasRef, active]);
};
