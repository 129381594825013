import { RefObject } from "react";

export interface IPainter {
  paint: () => void;
};

export abstract class Painter implements IPainter {
  constructor (public analyser: AnalyserNode, public canvasRef: RefObject<HTMLCanvasElement>, public sampleRate: number, public active: boolean) {
  }

  public abstract paint (): void;
};
