import { useState, useEffect } from "react";

export function useUserMedia (requestedMedia?: MediaStreamConstraints | undefined) {
  const [mediaStream, setMediaStream] = useState<MediaStream>();
  const [mediaStreamSource, setMediaStreamSource] = useState<MediaStreamAudioSourceNode>();
  const [mediaStreamDestination, setMediaStreamDestination] = useState<MediaStreamAudioDestinationNode>();
  const [audioContext, setAudioContext] = useState<AudioContext>();
  const [error, setError] = useState<any>(null);

  useEffect(() => {
    const getUserMedia = async () => {
      if (mediaStream || audioContext) return;
      if (!requestedMedia) return;
      try {
        const stream = await navigator.mediaDevices.getUserMedia(requestedMedia);
        setMediaStream(stream);
        const audioContext = new AudioContext();
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        audioContext.resume();
        setMediaStreamSource(audioContext.createMediaStreamSource(stream));
        setMediaStreamDestination(audioContext.createMediaStreamDestination());
        setAudioContext(audioContext);
      } catch (e) {
        setError(e);
      }
    };
    const cancel = () => {
      // if (!mediaStream) return;
      // mediaStream?.getTracks().forEach(track => {
      //   track.stop();
      // });
      // mediaStream?.getAudioTracks().forEach(track => {
      //   track.stop();
      // });
    };
    if (!mediaStream) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      getUserMedia();
    }
    return cancel;
  }, [mediaStream, requestedMedia]);

  return { mediaStream, audioContext, mediaStreamSource, mediaStreamDestination, error };
}
