import { Painter } from "./painter";

export class AVisualizerPainter extends Painter {
  // https://codepen.io/short/pen/VMBYam
  public paint = () => {
    if (!this.analyser || !this.canvasRef.current) return;
    const canvas = this.canvasRef.current;
    const context = canvas.getContext("2d", { willReadFrequently: true });
    if (context === null) return;

    const visualizer = {
      bar: {
        width: 1,
        height: 40,
        color: "black"
      },

      radius: 60
    };
    const width = canvas.width;
    const height = canvas.height * 1.5;

    const update = () => {
    };

    const render = () => {
      const spacing = 360 / this.analyser.frequencyBinCount;
      const origin = {
        x: width / 2 - visualizer.bar.width / 2,
        y: height / 2 - visualizer.bar.height
      };

      context.fillStyle = "black";
      context.clearRect(0, 0, width, height);

      const freqs = new Uint8Array(this.analyser.frequencyBinCount);
      const times = new Uint8Array(this.analyser.frequencyBinCount);
      this.analyser.getByteFrequencyData(freqs);
      this.analyser.getByteTimeDomainData(times);

      for (let i = 0; i < this.analyser.frequencyBinCount; i++) {
        const value = freqs[i] / 2;
        const degrees = i * spacing;
        const radians = degrees / 180 * Math.PI;

        context.save();
        context.translate(origin.x, origin.y);
        context.rotate(radians);
        context.fillRect(visualizer.bar.width / -2, visualizer.radius, visualizer.bar.width, visualizer.bar.height * value);
        context.restore();
      }

      update();
      requestAnimationFrame(render);
    };
    render();
  };
};
