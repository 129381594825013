import { findPitch } from "../../common/audio";
import { Painter } from "./painter";

export class FrequencyVisualizerPainter extends Painter {
  // source:
  // https://alexanderell.is/posts/tuner/
  public paint = () => {
    if (!this.canvasRef.current) return;
    if (!this.analyser) return;
    const canvas = this.canvasRef.current;
    const context = canvas.getContext("2d", { willReadFrequently: true });
    if (context == null) return;
    const bufferLength = this.analyser.frequencyBinCount;
    const dataArray = new Float32Array(bufferLength);

    const loop = () => {
      this.analyser.getFloatTimeDomainData(dataArray);
      context.clearRect(0, 0, canvas.width, canvas.height);
      const pitch = findPitch(Array.from(dataArray), this.sampleRate);
      if (pitch !== -1) {
        const hz = Math.round(pitch).toString() + " Hz";
        context.font = "48px 'IBM Plex Sans'";
        context.textAlign = "center";
        context.textBaseline = "middle";
        context.fillText(hz, canvas.width / 2, canvas.height / 2);
      }
      requestAnimationFrame(loop);
    };
    loop();
  };
}
