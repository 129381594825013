export function createDistortionNode (audioContext: AudioContext | null, amount: number): WaveShaperNode | null {
  if (!audioContext) return null;
  const makeDistortionCurve = (amount: number): Float32Array => {
    const k = typeof amount === "number" ? amount : 50;
    const nSamples = 44100;
    const curve = new Float32Array(nSamples);
    const deg = Math.PI / 180;
    for (let i = 0; i < nSamples; ++i) {
      const x = i * 2 / nSamples - 1;
      curve[i] = (3 + k) * x * 20 * deg / (Math.PI + k * Math.abs(x));
    }
    return curve;
  };
  const waveShaper = audioContext.createWaveShaper();
  waveShaper.curve = makeDistortionCurve(amount);
  waveShaper.oversample = "4x";
  return waveShaper;
};
