import { Painter } from "./painter";
import { findPitch } from "../../common/audio";

export class TunerVisualizerPainter extends Painter {
  // source:
  // https://alexanderell.is/posts/tuner/
  public paint = () => {
    if (!this.analyser) return;
    if (!this.canvasRef.current) return;

    const noteStrings = ["C", "C#", "D", "D#", "E", "F", "F#", "G", "G#", "A", "A#", "B"];
    function noteFromPitch (frequency: number) {
      const noteNum = 12 * (Math.log(frequency / 440) / Math.log(2));
      return Math.round(noteNum) + 69;
    }

    const canvas = this.canvasRef.current;
    const context = canvas.getContext("2d", { willReadFrequently: true });
    if (context == null) return;
    const bufferLength = this.analyser.frequencyBinCount;
    const dataArray = new Float32Array(bufferLength);

    const loop = () => {
      // analyser.getByteFrequencyData(dataArray);
      this.analyser.getFloatTimeDomainData(dataArray);
      const pitch = findPitch(Array.from(dataArray), this.sampleRate);
      context.clearRect(0, 0, canvas.width, canvas.height);
      if (pitch !== -1) {
        const note = noteStrings[noteFromPitch(pitch) % 12];
        context.font = "48px 'IBM Plex Sans'";
        context.textAlign = "center";
        context.textBaseline = "middle";
        context.fillText(note, canvas.width / 2, canvas.height / 2);
      }
      requestAnimationFrame(loop);
    };
    loop();
  };
}
