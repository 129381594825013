/* eslint-disable @typescript-eslint/no-floating-promises */
import { useEffect, useRef, useState } from "react";
import "./recorder.css";
import { FaMicrophone } from "react-icons/fa";

export const AudioRecorder = (props: { mediaStream: MediaStream; sampleRate: number }) => {
  const [recordedUrl, setRecordedUrl] = useState("");
  const mediaStreamRef = useRef<MediaStream>(props.mediaStream);
  const mediaRecorder = useRef<MediaRecorder>(new MediaRecorder(mediaStreamRef.current));
  const chunks = useRef<Blob[]>([]);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [isRecording, setIsRecording] = useState(false);
  const [recordingIntervalId, setRecordingIntervalId] = useState<any | null>(null);

  const startRecording = async () => {
    setIsRecording(true);
    try {
      mediaRecorder.current.ondataavailable = (e) => {
        console.log("data available");
        if (e.data.size > 0) {
          chunks.current.push(e.data);
          const recordedBlob = new Blob(
            chunks.current, { type: "audio/mp4" }
          );
          canvasRef.current && drawAudio(canvasRef.current, recordedBlob, props.sampleRate);
        }
      };
      mediaRecorder.current.onstop = () => {
        console.log("stop");
        const recordedBlob = new Blob(
          chunks.current, { type: "audio/mp4" }
        );
        const url = URL.createObjectURL(recordedBlob);
        setRecordedUrl(url);
        chunks.current = [];
        // canvasRef.current && drawAudio(canvasRef.current, recordedBlob);
      };
      mediaRecorder.current.start();
      mediaRecorder.current.requestData();
      const intervalId = setInterval(() => {
        if (mediaRecorder.current && mediaRecorder.current.state === "recording") {
          mediaRecorder.current.requestData();
        }
      }, 500);
      setRecordingIntervalId(intervalId);
    } catch (error) {
      console.error("Error accessing microphone:", error);
    }
  };
  const stopRecording = () => {
    if (mediaRecorder.current && mediaRecorder.current.state === "recording") {
      mediaRecorder.current.stop();
    }
    if (recordingIntervalId !== null) {
      clearInterval(recordingIntervalId as number);
      setRecordingIntervalId(null);
    }
    setIsRecording(false);
    // if (mediaStreamRef.current) {
    //   mediaStreamRef.current.getTracks().forEach((track) => {
    //     track.stop();
    //   });
    // }
  };

  useEffect(() => {
    if (canvasRef.current === null) return;
    const canvasCtx = canvasRef.current.getContext("2d");
    if (!canvasCtx) return;
    canvasCtx.fillStyle = "black";
    canvasCtx.fillRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    // canvasCtx.strokeStyle = "magenta";
    // canvasCtx.moveTo(0, canvasRef.current.height / 2);
    // canvasCtx.lineTo(canvasRef.current.width, canvasRef.current.height / 2);
    // canvasCtx.stroke();
  });

  async function drawAudio (canvas: HTMLCanvasElement, audioBlob: Blob, sampleRate: number) {
    const canvasCtx = canvas.getContext("2d");
    if (canvasCtx) {
      const arrayBuffer = await new Response(audioBlob).arrayBuffer();
      const audioContext = new AudioContext();
      const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
      const audioData = audioBuffer.getChannelData(0);
      const width = canvas.width;
      const height = canvas.height;
      const duration = audioData.length / sampleRate; // duration in seconds
      const startDataIndex = Math.max(0, audioData.length - sampleRate * 60); // start from the last 60 seconds
      const startX = Math.max(0, Math.min(width, (60 - duration) * width / 60)); // Adjust startX to ensure it never goes below zero or above the width of the canvas
      const dataRatio = Math.max(1, (audioData.length - startDataIndex) / (width - startX)); // Adjust dataRatio to ensure the waveform fits within the visible area of the canvas
      // todo: jarek aproximate it
      // to trzeba wyciagac do stanu - dane co sa aproksymowane itd, bez tego snika mi canvas
      const downsampleFactor = Math.ceil(dataRatio); // Downsample by taking every nth sample
      canvasCtx.fillStyle = "black";
      canvasCtx.fillRect(0, 0, canvas.width, canvas.height);
      canvasCtx.beginPath();
      canvasCtx.strokeStyle = "magenta";
      canvasCtx.moveTo(0, canvas.height / 2);
      canvasCtx.lineTo(canvas.width, canvas.height / 2);
      canvasCtx.stroke();
      canvasCtx.beginPath();
      canvasCtx.lineWidth = 2;
      canvasCtx.strokeStyle = "magenta";
      for (let i = startDataIndex; i < audioData.length; i += downsampleFactor) {
        const x = startX + (i - startDataIndex) / dataRatio;
        const y = (1 - audioData[i]) * height / 2;
        if (i === startDataIndex) {
          canvasCtx.moveTo(x, y);
        } else {
          canvasCtx.lineTo(x, y);
        }
      }
      canvasCtx.stroke();
    }
  }

  const display: string = isRecording ? "none" : "";
  return (
    <div>
    <div className={ isRecording ? "recorderTimeline" : "recorderTimelinePaused"} style={{ position: "relative" }}>
      <FaMicrophone onClick={() => { startRecording(); }} size={70} style={{ display, position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", color: "magenta" }} />
      <canvas ref={canvasRef} onClick={(x) => { isRecording ? stopRecording() : startRecording(); }} style={{ width: "100%", height: "100%" }} />;
    </div>
    <audio controls src={recordedUrl} />
  </div>
  );
};
export default AudioRecorder;
