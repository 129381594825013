import { RefObject } from "react";
import { BarVisualizerPainter } from "./barVisualizerPainter";
import { SpectoWaveVisualizerPainter } from "./spectoWaveVisualizerPainter";
import { Painter } from "./painter";
import { TunerVisualizerPainter } from "./tunerVisualizerPainter";
import { SpectoWaveVisualizerExtendedPainter } from "./spectoWaveVisualizerExtendedPainter";
import { FrequencyVisualizerPainter } from "./fequencyVisualizerPainter";
import { WaveformVisualizerPainter } from "./waveformVisualizerPainter";
import { OsciloscopeVisualizerPainter } from "./osciloscopeVisualizerPainter";
import { AVisualizerPainter } from "./aVisualizerPainter";

export type PainterType = "bars" | "spectoWave" | "spectoWaveExtended" | "tuner" | "frequency" | "waveform" | "oscilloscope" | "a";
export const painterFactory = (type: PainterType, analyser: AnalyserNode, canvasRef: RefObject<HTMLCanvasElement>, active: boolean, sampleRate: number): Painter => {
  switch (type) {
    case "bars":
      return new BarVisualizerPainter(analyser, canvasRef, sampleRate, active);
    case "spectoWave":
      return new SpectoWaveVisualizerPainter(analyser, canvasRef, sampleRate, active);
    case "spectoWaveExtended":
      return new SpectoWaveVisualizerExtendedPainter(analyser, canvasRef, sampleRate, active);
    case "tuner":
      return new TunerVisualizerPainter(analyser, canvasRef, sampleRate, active);
    case "frequency":
      return new FrequencyVisualizerPainter(analyser, canvasRef, sampleRate, active);
    case "waveform":
      return new WaveformVisualizerPainter(analyser, canvasRef, sampleRate, active);
    case "oscilloscope":
      return new OsciloscopeVisualizerPainter(analyser, canvasRef, sampleRate, active);
    case "a":
      return new AVisualizerPainter(analyser, canvasRef, sampleRate, active);
    default:
      throw new Error("Invalid type " + (type as unknown)?.toString());
  }
};
