export function createEchoNode (audioContext: AudioContext | null, delayTime: number, feedback: number): DelayNode | null {
  if (!audioContext) return null;
  const delay = audioContext.createDelay();
  delay.delayTime.value = delayTime;

  const gain = audioContext.createGain();
  gain.gain.value = feedback;

  delay.connect(gain);
  gain.connect(delay);
  return delay;
};
