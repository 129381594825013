import { Painter } from "./painter";

export class SpectoWaveVisualizerExtendedPainter extends Painter {
  // https://webaudioapi.com/samples/visualizer/
  public paint = () => {
    if (!this.analyser || !this.canvasRef.current) return;
    const canvas = this.canvasRef.current;
    const context = canvas.getContext("2d", { willReadFrequently: true });
    if (context === null) return;

    const playback = () => {
      const freqs = new Uint8Array(this.analyser.frequencyBinCount);
      const times = new Uint8Array(this.analyser.frequencyBinCount);
      this.analyser.smoothingTimeConstant = 0.8;
      this.analyser.getByteFrequencyData(freqs);
      this.analyser.getByteTimeDomainData(times);
      context.fillStyle = "black";
      context.fillRect(0, 0, canvas.width, canvas.height);
      for (let i = 0; i < this.analyser.frequencyBinCount; i++) {
        const value = freqs[i] * 12;
        const percent = value / 256;
        const height = canvas.height * percent;
        const offset = canvas.height - height - 1;
        const barWidth = canvas.width * 4 / this.analyser.frequencyBinCount;
        const hue = i / this.analyser.frequencyBinCount * 360;
        context.fillStyle = "hsl(" + hue + ", 100%, 50%)";
        context.fillRect(i * barWidth, offset, barWidth, height);
      }
      context.lineWidth = 1;
      context.strokeStyle = "white";
      context.fillStyle = "white";
      context.beginPath();
      const bufferLength = this.analyser.fftSize;
      const sliceWidth = canvas.width * 2 / bufferLength;
      let x = 0;
      for (let i = 0; i < bufferLength; i++) {
        const v = (times[i] / 128.0) * 8;
        const y = v * canvas.height / 2 - (canvas.height * 3.5);
        if (i === 0) {
          context.moveTo(x, y);
        } else {
          context.lineTo(x, y);
        }
        x += sliceWidth;
      }
      context.lineTo(canvas.width, canvas.height / 2);
      context.stroke();
      requestAnimationFrame(playback);
    };
    playback();
  };
}
