import { Painter } from "./painter";

export class BarVisualizerPainter extends Painter {
  public paint = () => {
    if (!this.analyser || !this.canvasRef.current) return;
    const canvas = this.canvasRef.current;
    const context = canvas.getContext("2d", { willReadFrequently: true });
    if (context === null) return;
    const animate = () => {
      if (!this.active) return;
      const bufferLength = this.analyser.frequencyBinCount;
      const dataArray = new Uint8Array(bufferLength);
      this.analyser.getByteFrequencyData(dataArray);
      const barWidth = (canvas.width / bufferLength) * 5;
      let x = 0;
      context.clearRect(0, 0, canvas.width, canvas.height);
      context.fillStyle = "black";
      for (let i = 0; i < bufferLength; i++) {
        const barHeight = dataArray[i];
        context.fillRect(x, canvas.height - barHeight, barWidth - 2, barHeight);
        x += barWidth;
      }
      requestAnimationFrame(animate);
    };
    animate();
  };
}
